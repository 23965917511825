@media (min-width: 1263px) and (max-width: 1920px) {
  .introSection {
    /* padding: 50px 80px; */
    padding-top: 160px;
    background-color: #f7a631;
    /* max-width: 1440px; */
    /* height: 1171px; */
    width: 100%;
  }

  .introSectionHeading {
    font-size: 64px;
    font-weight: 900;
    line-height: 78px;
    color: #fff;
    text-align: start;
    margin-bottom: 80px;
    position: relative;
    left: 80px;
    right: 80px;
    width: 950px;
  }

  .introSectionHeading span:first-of-type {
    color: #51164e;
  }

  .introSectionHeading span:nth-of-type(2) {
    color: #bf204f;
  }

  .introSectionText {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    height: 168px;
    width: 877px;
    color: #0b062e;
    margin-bottom: 80px;
    position: relative;
    left: 80px;
    right: 80px;
    text-align: start;
  }

  .introSectionCards {
    display: flex;
    justify-content: center;
    gap: 40px;
    height: 367px;
    padding-bottom: 160px;
  }

  .cardContainer {
    position: relative;
    height: 290px;
    width: 290px;
    border-radius: 40px;
    box-shadow: 0px 4px 24px 0px #51164e3d;
  }

  .cardWrapper {
    position: relative;
    height: 290px;
    width: 290px;
    border-radius: 40px;
    box-shadow: 0px 4px 24px 0px #51164e3d;
    overflow: hidden;
    z-index: 2;
  }

  .egroscoutCardWrapper {
    background-color: #51164e;
  }

  .egrocareCardWrapper {
    background-color: #bf204f;
  }

  .egroscanCardWrapper {
    background-color: #ee6823;
  }

  .cardWrapperBgImg {
    position: absolute;
    left: -25px;
    top: -20px;
    transition: opacity 0.3s ease-in-out; /* Smooth transition for disappearing */
  }

  .card {
    background-color: #fff;
    color: #1e174f;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    border-radius: 40px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    height: 100px;
    width: 234px;
    position: absolute;
    bottom: -77px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
  }

  .arrow {
    position: relative;
    top: -30px;
    height: 24px;
    width: 24px;
    transition: transform 0.3s ease-in-out;
  }

  .arrowLTR {
    left: 105px;
  }

  .arrowRTL {
    left: -105px;
  }

  .cardWrapperIconImg {
    position: absolute;
    top: 50%;
    width: 160px;
    height: 160px;
    transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */
  }
  .iconLTR {
    transform: translate(-50%, -50%);
  }

  .iconRTL {
    transform: translate(50%, -50%);
  }

  .cardContainer:hover .iconLTR {
    transform: translate(-50%, -50%) scale(1.1);
  }

  .cardContainer:hover .iconRTL {
    transform: translate(50%, -50%) scale(1.1);
  }

  .cardContainer:hover .cardWrapperBgImg {
    opacity: 0;
  }

  .cardContainer:hover .arrow {
    transform: translateY(200%);
  }

  .card span {
    transition: transform 0.3s ease-in-out; /* Smooth transition */
  }

  .cardContainer:hover span {
    transform: translateY(150%);
  }
}

@media (min-width: 900px) and (max-width: 1262px) {
  .introSection {
    /* padding: 50px 80px; */
    padding-top: 80px;
    background-color: #f7a631;
    /* max-width: 1440px; */
    /* height: 959px; */
    width: 100%;
  }

  .introSectionHeading {
    font-size: 56px;
    font-weight: 800;
    line-height: 68px;
    color: #fff;
    text-align: start;
    margin-bottom: 64px;
    position: relative;
    left: 40px;
    right: 40px;
    /* width: 732px; */
    width: 85%;
  }

  .introSectionHeading span:first-of-type {
    color: #51164e;
  }

  .introSectionHeading span:nth-of-type(2) {
    color: #bf204f;
  }

  .introSectionText {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    height: 168px;
    width: 85.6%;
    /* width: 877px; */
    color: #0b062e;
    margin-bottom: 64px;
    position: relative;
    left: 40px;
    right: 40px;
    text-align: start;
  }

  .introSectionCards {
    display: flex;
    justify-content: center;
    gap: 24px;
    height: 367px;
    padding-bottom: 80px;
  }

  .cardContainer {
    position: relative;
    height: 290px;
    width: 290px;
    border-radius: 40px;
    box-shadow: 0px 4px 24px 0px #51164e3d;
  }

  .cardWrapper {
    position: relative;
    height: 290px;
    width: 290px;
    border-radius: 40px;
    box-shadow: 0px 4px 24px 0px #51164e3d;
    overflow: hidden;
    z-index: 2;
  }

  .egroscoutCardWrapper {
    background-color: #51164e;
  }

  .egrocareCardWrapper {
    background-color: #bf204f;
  }

  .egroscanCardWrapper {
    background-color: #ee6823;
  }

  .cardWrapperBgImg {
    position: absolute;
    left: -25px;
    top: -20px;
    transition: opacity 0.3s ease-in-out; /* Smooth transition for disappearing */
  }

  .card {
    background-color: #fff;
    color: #1e174f;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    border-radius: 40px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    height: 100px;
    width: 234px;
    position: absolute;
    bottom: -77px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
  }

    .arrow {
    position: relative;
    top: -30px;
    height: 24px;
    width: 24px;
    transition: transform 0.3s ease-in-out;
  }

  .arrowLTR {
    left: 105px;
  }

  .arrowRTL {
    left: -105px;
  }

  .cardWrapperIconImg {
    position: absolute;
    top: 50%;
    width: 160px;
    height: 160px;
    transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */
  }
  .iconLTR {
    transform: translate(-50%, -50%);
  }

  .iconRTL {
    transform: translate(50%, -50%);
  }

  .cardContainer:hover .iconLTR {
    transform: translate(-50%, -50%) scale(1.1);
  }

  .cardContainer:hover .iconRTL {
    transform: translate(50%, -50%) scale(1.1);
  }

  .cardContainer:hover .cardWrapperBgImg {
    opacity: 0;
  }

  .cardContainer:hover .arrow {
    transform: translateY(200%);
  }

  .card span {
    transition: transform 0.3s ease-in-out; /* Smooth transition */
  }

  .cardContainer:hover span {
    transform: translateY(150%);
  }
}

@media (min-width: 580px) and (max-width: 899px) {
  .introSection {
    /* padding: 50px 80px; */
    padding-top: 80px;
    background-color: #f7a631;
    /* max-width: 1440px; */
    /* height: 1327px; */
    width: 100%;
  }

  .introSectionHeading {
    font-size: 56px;
    font-weight: 800;
    line-height: 68px;
    color: #fff;
    text-align: start;
    margin-bottom: 64px;
    position: relative;
    left: 40px;
    right: 40px;
    /* width: 732px; */
    width: 85%;
  }

  .introSectionHeading span:first-of-type {
    color: #51164e;
  }

  .introSectionHeading span:nth-of-type(2) {
    color: #bf204f;
  }

  .introSectionText {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    /* height: 168px; */
    width: 85.6%;
    /* width: 877px; */
    color: #0b062e;
    margin-bottom: 64px;
    position: relative;
    left: 40px;
    right: 40px;
    text-align: start;
  }

  .introSectionCards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    height: 951px;
    padding-bottom: 80px;
  }

  .cardContainer {
    position: relative;
    height: 240px;
    width: 240px;
    border-radius: 40px;
    box-shadow: 0px 4px 24px 0px #51164e3d;
  }

  .cardWrapper {
    position: relative;
    height: 240px;
    width: 240px;
    border-radius: 40px;
    box-shadow: 0px 4px 24px 0px #51164e3d;
    overflow: hidden;
    z-index: 2;
  }

  .egroscoutCardWrapper {
    background-color: #51164e;
  }

  .egrocareCardWrapper {
    background-color: #bf204f;
  }

  .egroscanCardWrapper {
    background-color: #ee6823;
  }

  .cardWrapperBgImg {
    position: absolute;
    width: 280px;
    height: 280px;
    left: -20px;
    top: -16px;
    transition: opacity 0.3s ease-in-out; /* Smooth transition for disappearing */
  }

  .card {
    background-color: #fff;
    color: #1e174f;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    border-radius: 40px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    height: 89px;
    width: 184px;
    position: absolute;
    bottom: -77px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
  }

  .arrow {
    position: relative;
    top: -30px;
    height: 24px;
    width: 24px;
    transition: transform 0.3s ease-in-out;
  }

  .arrowLTR {
    left: 80px;
  }

  .arrowRTL {
    left: -80px;
  }

  .cardWrapperIconImg {
    position: absolute;
    top: 50%;
    width: 160px;
    height: 160px;
    transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */
  }
  .iconLTR {
    transform: translate(-50%, -50%);
  }

  .iconRTL {
    transform: translate(50%, -50%);
  }

  .cardContainer:hover .iconLTR {
    transform: translate(-50%, -50%) scale(1.1);
  }

  .cardContainer:hover .iconRTL {
    transform: translate(50%, -50%) scale(1.1);
  }

  .cardContainer:hover .cardWrapperBgImg {
    opacity: 0;
  }

  .cardContainer:hover .arrow {
    transform: translateY(200%);
  }

  .card span {
    transition: transform 0.3s ease-in-out; /* Smooth transition */
  }

  .cardContainer:hover span {
    transform: translateY(150%);
  }
}

@media (max-width: 579px) {
  .introSection {
    /* padding: 50px 80px; */
    padding-top: 80px;
    background-color: #f7a631;
    /* max-width: 1440px; */
    /* height: 1327px; */
    width: 100%;
  }

  .introSectionHeading {
    font-size: 40px;
    font-weight: 700;
    line-height: 49px;
    color: #fff;
    text-align: start;
    margin-bottom: 80px;
    position: relative;
    left: 24px;
    right: 24px;
    width: 72%;
  }

  .introSectionHeading span:first-of-type {
    color: #51164e;
  }

  .introSectionHeading span:nth-of-type(2) {
    color: #bf204f;
  }

  .introSectionText {
    /*
    line-height: 28px;
    height: 168px;
    width: 877px;
    color: #0b062e;
    margin-bottom: 80px;
    position: relative;
    left: 80px;
    right: 80px;
    text-align: start; */
    display: none;
  }

  .introSectionCards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 100px;
    height: 951px;
    padding-bottom: 80px;
  }

  .cardContainer {
    position: relative;
    height: 240px;
    width: 240px;
    border-radius: 40px;
    box-shadow: 0px 4px 24px 0px #51164e3d;
  }

  .cardWrapper {
    position: relative;
    height: 240px;
    width: 240px;
    border-radius: 40px;
    box-shadow: 0px 4px 24px 0px #51164e3d;
    overflow: hidden;
    z-index: 2;
  }

  .egroscoutCardWrapper {
    background-color: #51164e;
  }

  .egrocareCardWrapper {
    background-color: #bf204f;
  }

  .egroscanCardWrapper {
    background-color: #ee6823;
  }

  .cardWrapperBgImg {
    position: absolute;
    width: 280px;
    height: 280px;
    left: -20px;
    top: -16px;
    transition: opacity 0.3s ease-in-out; /* Smooth transition for disappearing */
  }

  .card {
    background-color: #fff;
    color: #1e174f;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    border-radius: 40px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    padding: 20px;
    height: 89px;
    width: 184px;
    position: absolute;
    bottom: -77px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    overflow: hidden;
    cursor: pointer;
  }

   .arrow {
    position: relative;
    top: -30px;
    height: 24px;
    width: 24px;
    transition: transform 0.3s ease-in-out;
  }

  .arrowLTR {
    left: 80px;
  }

  .arrowRTL {
    left: -80px;
  }

  .cardWrapperIconImg {
    position: absolute;
    top: 50%;
    width: 160px;
    height: 160px;
    transition: transform 0.3s ease-in-out; /* Smooth transition for scaling */
  }
  .iconLTR {
    transform: translate(-50%, -50%);
  }

  .iconRTL {
    transform: translate(50%, -50%);
  }

  .cardContainer:hover .iconLTR {
    transform: translate(-50%, -50%) scale(1.1);
  }

  .cardContainer:hover .iconRTL {
    transform: translate(50%, -50%) scale(1.1);
  }

  .cardContainer:hover .cardWrapperBgImg {
    opacity: 0;
  }

  .cardContainer:hover .arrow {
    transform: translateY(200%);
  }

  .card span {
    transition: transform 0.3s ease-in-out; /* Smooth transition */
  }

  .cardContainer:hover span {
    transform: translateY(150%);
  }
}
