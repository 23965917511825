.packagesSection {
  padding: 160px 80px 80px 80px;
  background-color: #fba620;
}

.heading {
  font-size: 64px;
  font-weight: 900;
  line-height: 78px;
  color: #fff;
  text-align: start;
  width: 950px;
  margin-bottom: 80px;
}

.heading span:first-of-type,
.heading span:nth-of-type(3) {
  color: #51164e;
}

.heading span:nth-of-type(2),
.heading span:last-of-type {
  color: #bf204f;
}

.heading[dir="rtl"] span:first-of-type::before {
  content: "";
  display: block; /* Forces a line break */
}

.pricingContainer {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 40px;
  justify-content: center;

  /* height: 745px; */
}

.pricingCard {
  background: #fff;
  color: #0b062e;
  border-radius: 40px;
  padding: 40px 24px 24px 24px;
  box-shadow: 0px 4px 24px 0px #51164e3d;
  text-align: start;
  gap: 24px;
  /* width:290px */
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.cardTitle {
  color: #51164e;
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 0;
  margin-bottom: 0;
}

.featuresList {
  padding: 0;
  margin-top: 0;
  /* height: 61.2%; */
}

.featuresList li {
  display: flex;
  gap: 8px;
  align-items: flex-start; /* Ensures icon stays at the top of the text */
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #0b062e;
  margin-bottom: 8px;
}

.pricing {
  display: flex;
  flex-direction: column; /* Stack cards vertically */
  gap: 2px;
  margin-top: auto;
  /* grid-row: 4; */
}

.firstCard {
  background-color: #fff9eb;
  gap: 8px;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 16px;
  flex: 1; /* Makes cards equal width */
}

.secondCard {
  background-color: #fff9eb;
  gap: 8px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  padding: 16px;
  flex: 1; /* Makes cards equal width */
}

.firstCard p,
.secondCard p {
  display: flex; /* Enables flexbox */
  justify-content: space-between; /* Places content at the start and end */
  align-items: center;
  margin: 0; /* Removes default margin */
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #0b062e;
}

.egroscoutFirstCard {
  background-color: #fff9eb;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  padding: 16px;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #4f4b4e;
}
.egroscoutFirstCard p {
  margin: 0;
}

/* The number and "LE" are grouped */
.amount {
  display: inline-flex; /* Group the number and "LE" */
  align-items: baseline; /* Align number and "LE" naturally */
  gap: 4px; /* Add space between number and currency */
}

.amount span:not(.currency) {
  font-size: 24px;
  font-weight: 600;
  line-height: 30px;
  color: #0b062e;
}

.currency {
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #868085;
}

@media screen and (min-width: 768px) {
  .pricingContainer {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .pricingContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .packagesSection {
    padding: 80px 40px 40px 40px;
  }

  .heading {
    font-size: 56px;
    font-weight: 800;
    line-height: 68px;
    width: 745px;

    /* color: #fff;
  text-align: start;
  margin-bottom: 80px; */
  }

  .pricing {
    display: flex;
    flex-direction: row;
    gap: 2px;
  }

  .firstCard,
  .secondCard {
    flex: 1; /* Ensure both cards take equal space */
  }

  .firstCard {
    border-top-right-radius: 0;
    border-bottom-left-radius: 24px;
  }

  .secondCard {
    border-bottom-left-radius: 0;
    border-top-right-radius: 24px;
    display: flex; /* Enable flexbox */
    flex-direction: column; /* Stack content vertically */
    justify-content: center; /* Center content vertically */
  }

  .egroscoutFirstCard {
    border-top-right-radius: 0;
    border-bottom-left-radius: 24px;
    flex: 1;
  }

  :lang(ar) .firstCard {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;
  }

  :lang(ar) .secondCard {
    border-bottom-left-radius: 24px;
    border-top-left-radius: 24px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  :lang(ar) .egroscoutFirstCard {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 24px;
    border-top-right-radius: 24px;
  }
}

@media screen and (max-width: 767px)  {
  .packagesSection {
    padding: 80px 24px 40px 24px;
  }

  .heading {
    font-size: 40px;
    font-weight: 700;
    line-height: 49px;
    width: 307px;
  }

  .pricing {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .leftLogo, .rightLogo{
    width: 64px;
    height:68px;
  }
}

.ctaSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  color: #51164e;
  border-radius: 40px;
  box-shadow: 0px 4px 24px 0px #51164e3d;
  margin-top: 40px;
  position: relative;
  overflow: hidden;
}

.leftLogo {
  align-self: flex-end;
}

.rightLogo {
  align-self: flex-start;
}

.ctaSection > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.ctaSection p {
  color: #51164e;
  font-size: 40px;
  font-weight: 700;
  line-height: 49px;
  /* padding: 40px 24px 24px 24px; */
  margin: 0;
}

.ctaButton {
  background-color: #fba620;
  color: #51164e;
  border: none;
  padding: 19px 24px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}

:dir(rtl) .leftLogo {
  order: 3;
}

:dir(rtl) .ctaSection div {
  order: 2;
}

:dir(rtl) .rightLogo {
  order: 1;
}
