.advantagesContainer {
  background-image: url("../../../assets/Home/Advantages/bg-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #51164e;
  padding-top: 160px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.videoCard {
  width: 66%;
  height: 569px;
  background-color: #fff;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin-bottom: 160px;
}

.leftLogo {
  position: absolute;
  top: 0;
  left: 0;
}

.rightLogo {
  position: absolute;
  bottom: 0;
  right: 0;
}

.videoWrapper {
  position: relative;
  width: 91.6%;
  height: 489px;
  /* height: 86%; */
  /* height: auto; */
}

.video {
  width: 100%;
  height: 100%;
  border-width: 0;
  border-radius: 24px;
  box-shadow: 0px 4px 24px 0px #51164e3d;
}

.videoImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 24px;
  pointer-events: none;
}

.playBtnContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 240px;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(81, 22, 78, 0.8);
  border-radius: 50%;
  z-index: 3;
  cursor: pointer;
}

.playBtnInnerContainer {
  width: 192px;
  height: 192px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.whiteGlowAnimation {
  position: absolute;
  width: 192px;
  height: 192px;
  background-color: white;
  border-radius: 50%;
  opacity: 0.3;
  animation: expandGlow 2s ease-in-out infinite;
}

@keyframes expandGlow {
  0% {
    transform: scale(1);
    opacity: 0.3;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.3;
  }
  80% {
    transform: scale(1.5);
    opacity: 0.15;
  }
  100% {
    transform: scale(1.5);
    opacity: 0;
  }
}

.playBtnInnerContainer img {
  animation: pulse 2s ease-in-out infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.title {
  font-size: 64px;
  font-weight: 900;
  line-height: 78px;
  color: #fff;
  align-self: flex-start;
  margin-bottom: 80px;
  margin-top: 0px;
  position: relative;
  left: 80px;
  right: 80px;
  /* width: 50.55%; */
}

.title span:first-of-type {
  color: #fba620;
}

.title span:nth-of-type(2) {
  color: #bf204f;
}

.insightsContainer {
  display: flex;
  align-items: flex-start;
  /* width: 88.88%; */
  position: relative;
  left: 80px;
  right: 80px;
  gap: 2.77%;
  margin-bottom: 160px;
}

.insightCard {
  width: 20%;
  background-color: transparent; /* Transparent background to show bg pattern */
  text-align: start;
}

.insightTitle {
  font-size: 40px;
  font-weight: 700;
  line-height: 48.8px;
  color: #fba620;
  /* margin-bottom: 24px; */
  height: 196px;
}

.insightText {
  font-size: 20px;
  font-weight: 400;
  color: #e0e0e0;
  line-height: 28px;
}

/* Tablet Styles */
@media screen and (max-width: 1024px) {
  .videoCard {
    width: 80%;
    height: auto;
    aspect-ratio: 16/9;
    margin-bottom: 80px;
  }

  .videoWrapper {
    height: auto;
    aspect-ratio: 16/9;
  }

  .playBtnContainer {
    width: 180px;
    height: 180px;
  }

  .playBtnInnerContainer {
    width: 144px;
    height: 144px;
  }

  .whiteGlowAnimation {
    width: 144px;
    height: 144px;
  }

  .playBtnInnerContainer img {
    width: 48px;
    height: 48px;
  }

  .title {
    font-size: 48px;
    line-height: 58px;
    left: 40px;
    right: 40px;
    margin-bottom: 60px;
  }

  .insightsContainer {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    left: 40px;
    right: 40px;
    margin-bottom: 80px;
  }

  .insightCard {
    width: 45%;
  }

  .insightTitle {
    font-size: 32px;
    line-height: 40px;
    height: auto;
    margin-bottom: 16px;
  }

  .insightText {
    display: none;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .advantagesContainer {
    padding-top: 80px;
  }

  .videoCard {
    width: 90%;
    margin-bottom: 60px;
  }

  .playBtnContainer {
    width: 120px;
    height: 120px;
  }

  .playBtnInnerContainer {
    width: 96px;
    height: 96px;
  }

  .whiteGlowAnimation {
    width: 96px;
    height: 96px;
  }

  .playBtnInnerContainer img {
    width: 32px;
    height: 32px;
  }

  .title {
  font-size: 40px;
  font-weight: 700;
    line-height: 49px;
    left: 24px;
    right: 24px;
    margin-bottom: 40px;
  }

  .insightsContainer {
    /* flex-direction: column;
    left: 20px; */
    gap: 24px;
    margin-bottom: 60px;
    left: 24px;
    right: 24px;
  }

  .insightCard {
    width: 90%;
  }

  .insightTitle {
    font-size: 24px;
    line-height: 32px;
    height: auto;
    margin-bottom: 0;
  }

  .cardIcon {
    width: 80px;
    height: 80px;
    margin-bottom: 16px;
  }
}

/* Handle video aspect ratio */
.video, .videoImage {
  aspect-ratio: 16/9;
  /* height: auto; */
  width: 100%;
}