.footerContainer {
  background-color: #fba620;
  padding: 5.55% 5.55% 0 5.55%;
}

.footerContent {
  background-image: url("../../assets/Home/Advantages/bg-img.png");
  background-color: #51164e;
  color: #fba620;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;

  padding: 5.55%;
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.iconsRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.logo {
  width: 80px;
  height: 57px;
}

.socialIcons {
  display: flex;
  gap: 40px;
}

.icons {
  cursor: pointer;
}

.footerLinks {
  justify-content: center;
  display: flex;
  gap: 2.77%;
  /* gap: 40px; */
  width: 100%;
}

.footerLinks a {
  color: #fba620;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
}

.footerLinks a:hover {
  cursor: pointer;
}

.copyright {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  opacity: 0.56;
}

/* Mobile styles */
@media screen and (max-width: 480px) {
  .footerContent{
    gap: 30px;
  }

  .iconsRow {
    flex-direction: column;
    gap: 30px;
    align-items: flex-start;
  }

  .socialIcons {
    width: 100%;
    /* justify-content: center; */
  }
}
