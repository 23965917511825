.menu {
  background-color: rgba(11, 6, 46, 0.4);
  display: flex;
  gap: 10px;
  padding: 16px 80px !important;
  font-size: 16px;
  font-weight: 500;
  position: fixed;
  width: 90%;
  z-index: 100;
}

/* Adjust Menu Padding on Smaller Screens */
@media (max-width: 899px) {
  .menu {
    padding: 16px 48px !important;
  }
}

/* Hide Specific Links and Adjust Menu Height for Small Screens */
@media (max-width: 795px) {
  .menu {
    padding: 16px 37px !important;
    /* height: 112px; */
    flex-wrap: wrap;
  }

  .navItems {
    display: flex;
    gap: 10px;
  }

  .navLink {
    display: none;
  }

  .contactButton {
    display: none;
  }
}

@media (max-width: 576px) {
  .menu {
    padding: 16px 16px !important;
    width: 95%;
  }
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logo {
  aspect-ratio: 4.41;
  object-fit: contain;
  object-position: center;
  width: 42px;
  height: 30px;
}

.logoText {
  /* font-weight: bold; */
  font-size: 1.2rem;
  margin-left: 10px;
  color: #fff;
}

.navItems {
  align-self: stretch;
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: flex-end;
  flex-wrap: wrap;
  flex: 1;
  flex-basis: 0%;
  margin: auto 0;
}

@media (max-width: 991px) {
  .navItems {
    max-width: 100%;
  }
}

.navLink {
  align-self: stretch;
  margin: auto 0;
  text-decoration: none;
  color: #fff;
  line-height: 20px;
  cursor: pointer;
}

.contactButton {
  font-size: 16px;
  border-radius: 50px;
  border: 2px solid var(--Yello-Secondary, #fba620);
  min-height: 48px;
  /* width: 141px; */
  color: var(--Yello-Secondary, #fba620);
  font-weight: 700;
  margin: auto 0;
  padding: 19px 24px;
  background: none;
  cursor: pointer;
}

.icon {
  aspect-ratio: 1;
  position: relative;
  object-fit: contain;
  object-position: center;
  width: 32px;
  align-self: stretch;
  margin: auto 0;
  cursor: pointer;
}

.productsIcon {
  aspect-ratio: 1;
  object-fit: contain;
  object-position: center;
  width: 48px;
  align-self: stretch;
  margin: auto 0;
  cursor: pointer;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logoImage {
  aspect-ratio: 4.41;
  object-fit: contain;
  object-position: center;
  width: 132px;
  align-self: stretch;
  margin: auto 0;
}
