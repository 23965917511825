@media (min-width: 1263px) and (max-width: 1920px) {
  .heroSectionContainer {
    position: relative;
    height: 885px;
    width: 100%;
    /* margin: 0 auto; */
  }

  .backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #1e174f, #51164e);
    opacity: 0.92;
    z-index: -1;
  }

  .triangleWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
  }

  .triangle {
    position: absolute;
    width: auto;
    height: auto;
    opacity: 0.24;
  }

  .triangle1 {
    top: 213.2px;
    left: -100px;
    transform: rotate(105.77deg);
    animation: twist1 15s infinite linear;
  }

  .triangle2 {
    top: 616.95px;
    left: -150px;
    transform: rotate(35.87deg);
    animation: twist2 15s infinite linear;
  }

  .triangle3 {
    top: 344px;
    left: -600px;
    transform: rotate(-36.38deg);
    animation: twist3 15s infinite linear;
    color: #ee6823;
  }

  .triangle4 {
    top: 212.64px;
    left: -1100px;
    transform: rotate(106.26deg);
    animation: twist4 15s infinite linear;
  }

  .triangle5 {
    top: 558.34px;
    left: -1150px;
    transform: rotate(-165deg);
    animation: twist5 15s infinite linear;
  }

  @keyframes twist1 {
    0% {
      transform: translateX(0) rotate(-120.01deg);
    }
    100% {
      transform: translateX(calc(200vw)) rotate(240.01deg);
    }
  }

  @keyframes twist2 {
    0% {
      transform: translateX(0) rotate(35.87deg);
    }
    100% {
      transform: translateX(calc(200vw)) rotate(-324.13deg);
    }
  }

  @keyframes twist3 {
    0% {
      transform: translateX(0) rotate(165.4deg);
    }
    100% {
      transform: translateX(calc(200vw + 50px)) rotate(525.4deg);
    }
  }

  @keyframes twist4 {
    0% {
      transform: translateX(0) rotate(-15deg);
    }
    100% {
      transform: translateX(calc(200vw + 100px)) rotate(345deg);
    }
  }

  @keyframes twist5 {
    0% {
      transform: translateX(0) rotate(-165deg);
    }
    100% {
      transform: translateX(calc(200vw + 100px)) rotate(195deg);
    }
  }

  .heroSection {
    /* width: 1256px; */
    width: 87.2%;
    /* height:525px; */
    height: 59.3%;
    padding-top: 110px;
    max-width: 1440px;
  }

  .heroSectionHeading {
    color: #fff;
    text-transform: capitalize;
    font-size: 112px;
    font-weight:900;
    line-height: 136.5px;
    text-align: start;
    /* height:274px; */
    /* width:1278px; */
    width: 100%;
    /* height: 52.2%; */
    position: relative;
    left: 80px;
    right: 80px;
  }

  .highlight {
    color: #fba620;
  }

  .heroSectionText {
    color: #fff;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
    /* height:49px; */
    height: 9.33%;
    /* width: 44.75%; */
    /* width:562px; */
    display: flex;
    position: relative;
    left: 80px;
    right: 80px;
  }

  .heroSectionButton {
    align-self: flex-start;
    border-radius: 50px;
    background-color: #fba620;
    display: flex;
    margin-top: 72px;
    margin-bottom: 100px;
    width: 219px;
    min-height: 58px;
    gap: 16px;
    color: #51164e;
    text-transform: capitalize;
    padding: 19px 24px;
    border: none;
    cursor: pointer;
    position: relative;
    left: 80px;
    right: 80px;
  }

  

  .heroSectionButtonText {
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
  }

  .arrowIcon {
    width: 13.7px;
    height: 16px;
  }

  .logoContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 80px;
    margin-top: 49px;
  }

  /* .logoIcon {
    position: absolute;
    top: 684px;
    left: 74.4%;
  } */
}

@media (min-width: 980px) and (max-width: 1262px) {
  .heroSectionContainer {
    position: relative;
    height: 991px;
    width: 100%;
    /* margin: 0 auto; */
  }

  .backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #1e174f, #51164e);
    opacity: 0.92;
    z-index: -1;
  }

  .triangleWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
  }

  .triangle {
    position: absolute;
    width: auto;
    height: auto;
    opacity: 0.24;
  }

  .triangle1 {
    top: 213.2px;
    left: -100px;
    transform: rotate(105.77deg);
    animation: twist1 15s infinite linear;
  }

  .triangle2 {
    top: 616.95px;
    left: -150px;
    transform: rotate(35.87deg);
    animation: twist2 15s infinite linear;
  }

  .triangle3 {
    top: 344px;
    left: -600px;
    transform: rotate(-36.38deg);
    animation: twist3 15s infinite linear;
    color: #ee6823;
  }

  .triangle4 {
    top: 212.64px;
    left: -1100px;
    transform: rotate(106.26deg);
    animation: twist4 15s infinite linear;
  }

  .triangle5 {
    top: 558.34px;
    left: -1150px;
    transform: rotate(-165deg);
    animation: twist5 15s infinite linear;
  }

  @keyframes twist1 {
    0% {
      transform: translateX(0) rotate(-120.01deg);
    }
    100% {
      transform: translateX(calc(200vw)) rotate(240.01deg);
    }
  }

  @keyframes twist2 {
    0% {
      transform: translateX(0) rotate(35.87deg);
    }
    100% {
      transform: translateX(calc(200vw)) rotate(-324.13deg);
    }
  }

  @keyframes twist3 {
    0% {
      transform: translateX(0) rotate(165.4deg);
    }
    100% {
      transform: translateX(calc(200vw + 50px)) rotate(525.4deg);
    }
  }

  @keyframes twist4 {
    0% {
      transform: translateX(0) rotate(-15deg);
    }
    100% {
      transform: translateX(calc(200vw + 100px)) rotate(345deg);
    }
  }

  @keyframes twist5 {
    0% {
      transform: translateX(0) rotate(-165deg);
    }
    100% {
      transform: translateX(calc(200vw + 100px)) rotate(195deg);
    }
  }

  .heroSection {
    /* width: 1256px; */
    width: 92.2%;
    /* height:525px; */
    height: 75.8%;
    padding-top: 80px;
    /* max-width: 1440px; */
  }

  .heroSectionHeading {
    color: #fff;
    text-transform: capitalize;
    font-size: 112px;
    font-weight: 900;
    line-height: 136.5px;
    text-align: start;
    /* height:274px; */
    /* width:1278px; */
    width: 61%;
    min-width: 78%;
    /* height: 55.3%; */
    position: relative;
    left: 40px;
    right: 40px;
  }

  .highlight {
    color: #fba620;
  }

  .heroSectionText {
    color: #fff;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
    /* height:49px; */
    height: 5%;
    /* width: 55%; */
    /* width:562px; */
    display: flex;
    position: relative;
    left: 40px;
    right: 40px;
  }

  .heroSectionButton {
    align-self: flex-start;
    border-radius: 50px;
    background-color: #fba620;
    display: flex;
    margin-top: 48px;
    /* margin-bottom: 80px; */
    width: 219px;
    min-height: 58px;
    gap: 16px;
    color: #51164e;
    text-transform: capitalize;
    padding: 19px 24px;
    border: none;
    cursor: pointer;
    position: relative;
    left: 40px;
    right: 40px;
  }

  .heroSectionButtonText {
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
  }

  .arrowIcon {
    width: 13.7px;
    height: 16px;
  }

  .logoContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 40px;
    margin-top: 42px;
  }

  .logoIcon {
    /* position: absolute; */
    width: 170px;
    height: 396px;
    /* top: 874px;
    left: 79.5%; */
  }
}

@media (min-width: 560px) and (max-width: 979px) {
  .heroSectionContainer {
    position: relative;
    height: 991px;
    width: 100%;
    /* margin: 0 auto; */
  }

  .backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #1e174f, #51164e);
    opacity: 0.92;
    z-index: -1;
  }

  .triangleWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
  }

  .triangle {
    position: absolute;
    width: auto;
    height: auto;
    opacity: 0.24;
  }

  .triangle1 {
    top: 213.2px;
    left: -100px;
    transform: rotate(105.77deg);
    animation: twist1 15s infinite linear;
  }

  .triangle2 {
    top: 616.95px;
    left: -150px;
    transform: rotate(35.87deg);
    animation: twist2 15s infinite linear;
  }

  .triangle3 {
    top: 344px;
    left: -600px;
    transform: rotate(-36.38deg);
    animation: twist3 15s infinite linear;
    color: #ee6823;
  }

  .triangle4 {
    top: 212.64px;
    left: -1100px;
    transform: rotate(106.26deg);
    animation: twist4 15s infinite linear;
  }

  .triangle5 {
    top: 558.34px;
    left: -1150px;
    transform: rotate(-165deg);
    animation: twist5 15s infinite linear;
  }

  @keyframes twist1 {
    0% {
      transform: translateX(0) rotate(-120.01deg);
    }
    100% {
      transform: translateX(calc(200vw)) rotate(240.01deg);
    }
  }

  @keyframes twist2 {
    0% {
      transform: translateX(0) rotate(35.87deg);
    }
    100% {
      transform: translateX(calc(200vw)) rotate(-324.13deg);
    }
  }

  @keyframes twist3 {
    0% {
      transform: translateX(0) rotate(165.4deg);
    }
    100% {
      transform: translateX(calc(200vw + 50px)) rotate(525.4deg);
    }
  }

  @keyframes twist4 {
    0% {
      transform: translateX(0) rotate(-15deg);
    }
    100% {
      transform: translateX(calc(200vw + 100px)) rotate(345deg);
    }
  }

  @keyframes twist5 {
    0% {
      transform: translateX(0) rotate(-165deg);
    }
    100% {
      transform: translateX(calc(200vw + 100px)) rotate(195deg);
    }
  }

  .heroSection {
    /* width: 1256px; */
    width: 92.2%;
    /* height:525px; */
    height: 75.8%;
    padding-top: 80px;
    /* max-width: 1440px; */
  }

  .heroSectionHeading {
    color: #fff;
    text-transform: capitalize;
    font-size: 84px;
    font-weight: 900;
    line-height: 102px;
    text-align: start;
    /* height:274px; */
    /* width:1278px; */
    width: 61%;
    min-width: 78%;
    /* height: 55.3%; */
    position: relative;
    left: 40px;
    right: 40px;
  }

  .highlight {
    color: #fba620;
  }

  .heroSectionText {
    color: #fff;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
    /* height:49px; */
    height: 5%;
    /* width: 55%; */
    /* width:562px; */
    display: flex;
    position: relative;
    left: 40px;
    right: 40px;
  }

  .heroSectionButton {
    align-self: flex-start;
    border-radius: 50px;
    background-color: #fba620;
    display: flex;
    margin-top: 48px;
    /* margin-bottom: 80px; */
    width: 219px;
    min-height: 58px;
    gap: 16px;
    color: #51164e;
    text-transform: capitalize;
    padding: 19px 24px;
    border: none;
    cursor: pointer;
    position: relative;
    left: 40px;
    right: 40px;
  }

  .heroSectionButtonText {
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
  }

  .arrowIcon {
    width: 13.7px;
    height: 16px;
  }

  .logoContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 40px;
    margin-top: 74px;
  }

  .logoIcon {
    /* position: absolute; */
    /* width: 125px;
    height: 291px; */

    display:none
    /* top: 905px;
    left: 76%; */
  }
}

@media (min-width: 371px) and (max-width: 559px) {
  .heroSectionContainer {
    position: relative;
    height: 780px;
    width: 100%;
    /* margin: 0 auto; */
  }

  .backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #1e174f, #51164e);
    opacity: 0.92;
    z-index: -1;
  }

  .triangleWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
  }

  .triangle {
    position: absolute;
    width: auto;
    height: auto;
    opacity: 0.24;
  }

  .triangle1 {
    top: 213.2px;
    left: -100px;
    transform: rotate(105.77deg);
    animation: twist1 15s infinite linear;
  }

  .triangle2 {
    top: 616.95px;
    left: -150px;
    transform: rotate(35.87deg);
    animation: twist2 15s infinite linear;
  }

  .triangle3 {
    top: 344px;
    left: -600px;
    transform: rotate(-36.38deg);
    animation: twist3 15s infinite linear;
    color: #ee6823;
  }

  .triangle4 {
    top: 212.64px;
    left: -1100px;
    transform: rotate(106.26deg);
    animation: twist4 15s infinite linear;
  }

  .triangle5 {
    top: 558.34px;
    left: -1150px;
    transform: rotate(-165deg);
    animation: twist5 15s infinite linear;
  }

  @keyframes twist1 {
    0% {
      transform: translateX(0) rotate(-120.01deg);
    }
    100% {
      transform: translateX(calc(200vw)) rotate(240.01deg);
    }
  }

  @keyframes twist2 {
    0% {
      transform: translateX(0) rotate(35.87deg);
    }
    100% {
      transform: translateX(calc(200vw)) rotate(-324.13deg);
    }
  }

  @keyframes twist3 {
    0% {
      transform: translateX(0) rotate(165.4deg);
    }
    100% {
      transform: translateX(calc(200vw + 50px)) rotate(525.4deg);
    }
  }

  @keyframes twist4 {
    0% {
      transform: translateX(0) rotate(-15deg);
    }
    100% {
      transform: translateX(calc(200vw + 100px)) rotate(345deg);
    }
  }

  @keyframes twist5 {
    0% {
      transform: translateX(0) rotate(-165deg);
    }
    100% {
      transform: translateX(calc(200vw + 100px)) rotate(195deg);
    }
  }

  .heroSection {
    /* width: 1256px; */
    width: 87.2%;
    /* height:525px; */
    /* height: 65%; */
    padding-top: 80px;
  }

  .heroSectionHeading {
    color: #fff;
    text-transform: capitalize;
    font-size: 56px;
    font-weight: 800;
    line-height: 68px;
    text-align: start;
    /* height:274px; */
    /* width:1278px; */
    width: 100%;
    /* height: 35%; */
    position: relative;
    left: 24px;
    right: 24px;
  }

  .highlight {
    color: #fba620;
  }

  .heroSectionText {
    color: #fff;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
    height: 12.5%;
    /* width: 75.5%; */
    /* width:562px; */
    display: flex;
    text-align: start;
    position: relative;
    left: 24px;
    right: 24px;
  }

  .heroSectionButton {
    align-self: flex-start;
    border-radius: 50px;
    background-color: #fba620;
    display: flex;
    margin-top: 40px;
    margin-bottom: 80px;
    width: 219px;
    min-height: 58px;
    gap: 16px;
    color: #51164e;
    text-transform: capitalize;
    padding: 19px 24px;
    border: none;
    cursor: pointer;
    position: relative;
    left: 24px;
    right: 24px;
  }

  .heroSectionButtonText {
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
  }

  .arrowIcon {
    width: 13.7px;
    height: 16px;
  }

  .logoContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
    margin-top: 110px;
  }

  .ar {
    margin-top: 155px;
  }

  .logoIcon {
    /* position: absolute;
    top: 725px;
    left: 72.3%; */
    /* width: 80px;
    height: 186px; */
    display:none
  }
}

@media (max-width: 370px) {
  .heroSectionContainer {
    position: relative;
    height: 780px;
    width: 100%;
    /* margin: 0 auto; */
  }

  .backgroundVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -2;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, #1e174f, #51164e);
    opacity: 0.92;
    z-index: -1;
  }

  .triangleWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    overflow: hidden;
  }

  .triangle {
    position: absolute;
    width: auto;
    height: auto;
    opacity: 0.24;
  }

  .triangle1 {
    top: 213.2px;
    left: -100px;
    transform: rotate(105.77deg);
    animation: twist1 15s infinite linear;
  }

  .triangle2 {
    top: 616.95px;
    left: -150px;
    transform: rotate(35.87deg);
    animation: twist2 15s infinite linear;
  }

  .triangle3 {
    top: 344px;
    left: -600px;
    transform: rotate(-36.38deg);
    animation: twist3 15s infinite linear;
    color: #ee6823;
  }

  .triangle4 {
    top: 212.64px;
    left: -1100px;
    transform: rotate(106.26deg);
    animation: twist4 15s infinite linear;
  }

  .triangle5 {
    top: 558.34px;
    left: -1150px;
    transform: rotate(-165deg);
    animation: twist5 15s infinite linear;
  }

  @keyframes twist1 {
    0% {
      transform: translateX(0) rotate(-120.01deg);
    }
    100% {
      transform: translateX(calc(200vw)) rotate(240.01deg);
    }
  }

  @keyframes twist2 {
    0% {
      transform: translateX(0) rotate(35.87deg);
    }
    100% {
      transform: translateX(calc(200vw)) rotate(-324.13deg);
    }
  }

  @keyframes twist3 {
    0% {
      transform: translateX(0) rotate(165.4deg);
    }
    100% {
      transform: translateX(calc(200vw + 50px)) rotate(525.4deg);
    }
  }

  @keyframes twist4 {
    0% {
      transform: translateX(0) rotate(-15deg);
    }
    100% {
      transform: translateX(calc(200vw + 100px)) rotate(345deg);
    }
  }

  @keyframes twist5 {
    0% {
      transform: translateX(0) rotate(-165deg);
    }
    100% {
      transform: translateX(calc(200vw + 100px)) rotate(195deg);
    }
  }

  .heroSection {
    /* width: 1256px; */
    width: 87.2%;
    /* height:525px; */
    /* height: 65%; */
    padding-top: 80px;
  }

  .heroSectionHeading {
    color: #fff;
    text-transform: capitalize;
    font-size: 56px;
    font-weight: 800;
    line-height: 68px;
    text-align: start;
    /* height:274px; */
    /* width:1278px; */
    width: 100%;
    /* height: 35%; */
    position: relative;
    left: 24px;
    right: 24px;
  }

  .highlight {
    color: #fba620;
  }

  .heroSectionText {
    color: #fff;
    text-transform: capitalize;
    font-size: 40px;
    font-weight: 700;
    height: 12.5%;
    /* width: 75.5%; */
    /* width:562px; */
    display: flex;
    text-align: start;
    position: relative;
    left: 24px;
    right: 24px;
  }

  .heroSectionButton {
    align-self: flex-start;
    border-radius: 50px;
    background-color: #fba620;
    display: flex;
    margin-top: 40px;
    margin-bottom: 80px;
    width: 219px;
    min-height: 58px;
    gap: 16px;
    color: #51164e;
    text-transform: capitalize;
    padding: 19px 24px;
    border: none;
    cursor: pointer;
    position: relative;
    left: 24px;
    right: 24px;
  }

  .heroSectionButtonText {
    line-height: 20px;
    font-size: 16px;
    font-weight: 700;
  }

  .arrowIcon {
    width: 13.7px;
    height: 16px;
  }

  .logoContainer {
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
    margin-top: -38px;
  }

  .logoIcon {
    /* position: absolute;
    top: 725px;
    left: 72.3%; */
    /* width: 80px;
    height: 186px; */
    display:none
  }
}

:lang(ar) .heroSectionButton{
  width: 252px;
}
