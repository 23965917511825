.container {
  background-image: url("../../../assets/Home/KnowMore/bg-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #51164e;
  padding-top: 160px;
  padding-bottom: 160px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  font-size: 40px;
  font-weight: 700;
  line-height: 49px;
  color: #fff;
  margin-top: 0px;
  margin-bottom: 40px;
}

.button {
  background-color: #fba620;
  color: #51164e;
  border: none;
  padding: 19px 24px;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
}
