.container {
  background-image: url("../../../assets/Home/LeafCheck/bg-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding: 160px 80px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.title {
  font-size: 64px;
  font-weight: 900;
  line-height: 78px;
  color: #fff;
  text-align: start;
  flex: 1;
}

.title span:first-of-type,
.title span:nth-of-type(3) {
  color: #51164e;
}

.title span:nth-of-type(2),
.title span:nth-of-type(4) {
  color: #bf204f;
}

.card {
  flex: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-radius: 40px;
  position: relative;
  width: 43%;
  height: 620px;
  /* height: 66%; */
}

.logo {
  position: absolute;
  right: 0;
  top:0;
}

:lang(ar) .logo {
  align-self: flex-start;
}

.cardWrapper {
  border-radius: 24px;
  box-shadow: 0px 4px 24px 0px #51164e3d;
  width: 87.1%;
  height: 540px;
}

 .swiper {
  height: 100%;
  border-radius: 24px;
  direction: ltr;
}

body[dir="rtl"] .swiper {
  direction: rtl;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Tablet Styles */
@media screen and (max-width: 1024px) {
  .container {
    padding: 80px 40px;
  }

  .title {
    font-size: 56px;
    font-weight: 800;
    line-height: 68px;
  }

  .card {
    /* width: 452px; */
    width: 44.1%;
    height: 452px;
  }

  .cardWrapper {
    /* width: 372px; */
    width: 82.3%;
    height: 372px;
  }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .container {
    padding: 80px 24px;
    flex-direction: column;
  }

  .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 49px;
  }

  .card {
    /* width: 327px; */
    width: 87.2%;
    height: 327px;
    padding-top: 20px;
    padding-bottom: 20px;

  }

  .cardWrapper {
    /* width: 269px; */
    width: 82.3%;
    height: 269px;
  }

  .logo {
    width: 95px;
    height: 101px;
  }
}
