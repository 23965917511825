body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

:lang(ar) body, :lang(ar) button, :lang(ar) input, :lang(ar) input::placeholder {
  font-family: "Tajawal", sans-serif;
}

:lang(en) button, :lang(en) input, :lang(en) input::placeholder {
  font-family: "Montserrat", sans-serif;
}

html,
body {
  overflow-x: hidden;
}
