 .heroSection {
  background-color: #51164E;
  padding: 160px 80px;
  display: flex;
  align-items: center;
  gap: 40px;
}

.leftSection {
  width: 43%;
  text-align: start;
  display: flex;
  flex-direction: column;
  padding-bottom: 160px;
}

.rightSection {
  width: 43%;
  text-align: start;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}


.iconTitleWrapper {
  display: flex;
  align-items: center;
  gap: 24px;
}

.icon {
  width: 100px;
  height: 100px;
}

.title {
  font-size: 56px;
  font-weight: 800;
  line-height: 68px;
  color: #fff;
  /* width: 326px; */
  width: 340px;
}

.description {
  font-size: 40px;
  font-weight: 700;
  line-height: 49px;
  color: #fba620;
  /* width: 95%; */
}

.text {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #fff;
  /* width: 95%; */
}