.container {
  background-image: url("../../assets/Home/Advantages/bg-img.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #51164e;
  padding: 80px 80px 180px 80px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-size: 64px;
  font-weight: 900;
  line-height: 78px;
  color: #fff;
  margin-bottom: 95px;
  margin-top: 0px;
}

.title span:first-of-type {
  color: #fba620;
}

.title span:nth-of-type(2) {
  color: #bf204f;
}

.close {
  object-fit: contain;
  margin-bottom: 95px;
  cursor: pointer;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 84.7%;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  border: none;
  padding: 0;
}

.input {
  flex: 1;
  padding: 14px 24px;
  border: 1px solid #ffffff7a;
  border-radius: 48px;
  background-color: #0b062e14;
  color: #fff;
}

.input::placeholder {
  color: #fff;
  opacity: 0.48;
  line-height: 20px;
  font-size: 16px;
  font-weight: 400;
}

.input:focus {
  outline: none;
}

select {
  color: #fff;
  opacity: 0.48;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  appearance: none; 
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url("../../assets/ContactUs/down-arrow.png") no-repeat;
  background-position: right 1rem center;
}

:lang(ar) select {
  background-position: left 1rem center;
}

select option {
  color: #0B062E;
  font-size: 16px;
  font-weight: 400;
  line-height:20px;
}

.crops {
  border: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  text-align: start;
  width: 100%;
}

.crops legend {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
}

.checkboxLabel {
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  display: inline-block;
  /* width: 186px; */
  margin-bottom: 24px;
}

.checkboxLabel input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: #0b062e0a;
  border: 1px solid #ffffff7a;
  border-radius: 4px;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  vertical-align: middle;
  cursor: pointer;
}

:lang(ar) .checkboxLabel input[type="checkbox"] {
  margin-left: 10px;
}

.checkboxLabel input[type="checkbox"]:checked {
  background-color: #fba620;
  border-color: #fba620;
}

.checkboxLabel input[type="checkbox"]:checked::after {
  content: "✓";
  display: block;
  text-align: center;
  color: #51164e;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
}

.submitButton {
  background-color: #fba620;
  color: #51164e;
  padding: 19px 24px;
  border: none;
  border-radius: 50px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  width: 198px;
  align-self: flex-end;
}

@media (max-width: 1024px) {
  .container {
    padding: 80px 40px 470px 40px;
  }

  .form {
    width: 100%;
  }

  .row:not(:first-child) {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 80px 24px 40px 24px;
  }

  .title {
    font-size: 40px;
    font-weight: 700;
    line-height: 49px;
    margin-bottom: 40px;
  }

  .close {
    margin-bottom: 40px;
  }

  .form {
    width: 100%;
    gap: 24px;
  }

   .row {
    flex-direction: column;
    gap: 24px;
  }

  .crops {
    grid-template-columns: repeat(2, 1fr);
  }
}
