.container {
  background-color: #ee6823;
  display: flex;
  /* align-items: flex-start; */
  /* padding: 160px 80px; */
  position: relative;
   padding-left: 5.55%;
  padding-right: 5.55%;
  gap: 2.77%;
}

.leftSection {
  width: 43%;
  text-align: start;
  display: flex;
  flex-direction: column;
  padding-top: 160px;
  padding-bottom: 160px;
}

.rightSection {
  width: 43%;
  text-align: start;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
}

.overlay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #EE6823 0%, rgba(238, 104, 35, 0.24) 50%, #EE6823 100%);
  z-index: 1;
}

.video {
  width: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  object-fit: cover;
  height: 100%;
  border-radius: 40px;
}

.iconTitleWrapper {
  display: flex;
  align-items: center;
  gap: 40px;
}

.icon {
  width: 125px;
  height: 125px;
}

.title {
  font-size: 56px;
  font-weight: 800;
  line-height: 68px;
  color: #fff;
  /* width: 326px; */
  width: 430px;
}

.description {
  font-size: 40px;
  font-weight: 700;
  line-height: 49px;
  color: #fba620;
  /* width: 95%; */
}

.text {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #fff;
  /* width: 95%; */
}

.buttonsContainer {
  display: flex;
  gap: 24px;
  margin-top: 40px;
}

.knowMoreButton {
  border-radius: 50px;
  background-color: transparent;
  display: flex;
  /* width: 176px; */
  min-height: 58px;
  gap: 16px;
  color: #fba620;
  text-transform: capitalize;
  padding: 19px 24px;
  font-size: 16px;
  font-weight: 700;
  border: 2px solid #fba620;
  cursor: pointer;
}
.downloadButton {
  border-radius: 50px;
  background-color: #fba620;
  width: 209px;
  min-height: 58px;
  gap: 16px;
  color: #51164e;
  text-transform: capitalize;
  padding: 19px 24px;
  font-size: 16px;
  font-weight: 700;
  border: none;
  cursor: pointer;
}

.logoIcon {
  /* margin-top: -160px; */
  width: 201px;
  height: 214px;
  align-self: flex-end;
  z-index: 2;
}

@media screen and (min-width: 536px) and (max-width: 1900px) {
  .container {
    width:100%
  }
}

/* Tablet Styles (max-width: 768px) */
@media screen and (max-width: 768px) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
    gap: 20px;
  }

  .leftSection {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .iconTitleWrapper {
    gap: 24px;
  }

  .icon {
    width: 80px;
    height: 80px;
  }

  .title {
    font-size: 40px;
    line-height: 48px;
    width: auto;
  }

  .description {
    font-size: 32px;
    line-height: 39px;
    margin-top: 24px;
  }

  .text {
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
  }

  .logoIcon {
    width: 147px;
    height: 160px;
  }

  .video {
    border-radius: 32px;
  }
}

/* Mobile Styles (max-width: 480px) */
@media screen and (max-width: 535px) {
  .container {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
  }

  .leftSection {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 24px;
  }

  .rightSection {
    width: 100%;
    height: 400px;
  }

  .iconTitleWrapper {
    gap: 16px;
  }

  .icon {
    width: 60px;
    height: 60px;
  }

  .title {
    font-size: 32px;
    line-height: 39px;
  }

  .description {
    font-size: 24px;
    line-height: 29px;
    margin-top: 16px;
  }

  .text {
    display: none;
  }

  .video {
    border-radius: 24px;
  }

  .logoIcon {
    /* width: 60px;
    height: 120px; */
    display:none
  }
}